import { Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subject } from "rxjs";

@Injectable()
export class ScrollService {
  private scrollPositions = new BehaviorSubject<{ [key: string]: number }>({});
  scrollPosition = this.scrollPositions.asObservable().pipe();

  constructor() {}

  // Update scroll position for a specific container
  updateScrollPosition(key: string, position: number): void {
    const currentPositions = this.scrollPositions.value;
    this.scrollPositions.next({ ...currentPositions, [key]: position });
  }

  // Retrieve scroll position for a specific container
  getScrollPosition(key: string): Observable<any> {
    return this.scrollPositions.asObservable().pipe(
      map(positions => positions[key] || 0),
      distinctUntilChanged(), // Emit only if the value actually changes
      filter(position => position !== undefined) // Ensure we have a valid position
    );
  }
}
