import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PollService {
  private apiPrefix = '/client/polls';

  constructor(
    private api: ApiService
  ) {
  }

  getDashboardPoll() {
    return this.api.get(`${this.apiPrefix}/dashboard-poll`);
  }

  createPollAnswer(pollId: number, answerId: number) {
    return this.api.post(`${this.apiPrefix}/${pollId}/answers`, {
      pollOptionId: answerId,
    });
  }
}
