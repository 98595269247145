import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class PlaylistService {
  private apiPrefix = '/client/playlists';

  constructor(
    private api: ApiService,
  ) {
  }

  getMainPlaylist(): Observable<any> {
    return this.api.get(`${this.apiPrefix}/dashboard-playlist`);
  }

  getPlaylistVideos(id: number, queryString = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}/videos${queryString}`)
  }

  getPlaylistWithId(id: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  getPlaylists(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }
}
