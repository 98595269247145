import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class VideoCommentService {
  private apiPrefix = '/client/videos/';

  constructor(
    private api: ApiService,
  ) {
  }

  getComments(videoId: number, queryString = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}${videoId}/comments${queryString}`);
  }

  getCommentAnswers(videoId: number, commentId: number) {
    return this.api.get(`${this.apiPrefix}${videoId}/comments/${commentId}/answers?t=${new Date().getTime()}`);
  }

  likeComment(videoId: number, commentId: number) {
    return this.api.patch(`${this.apiPrefix}${videoId}/comments/${commentId}/like`, {});
  }

  deleteLikeComment(videoId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${videoId}/comments/${commentId}/like`);
  }

  dislikeComment(videoId: number, commentId: number) {
    return this.api.patch(`${this.apiPrefix}${videoId}/comments/${commentId}/dislike`, {});
  }

  deleteDislikeComment(videoId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${videoId}/comments/${commentId}/dislike`);
  }

  sendComment(videoId: number, data: object) {
    return this.api.post(`${this.apiPrefix}${videoId}/comments`, data);
  }

  editComment(videoId: number, commentId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}${videoId}/comments/${commentId}`, data);
  }

  deleteComment(videoId: number, commentId: number) {
    return this.api.delete(`${this.apiPrefix}${videoId}/comments/${commentId}`);
  }
}
