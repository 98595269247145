import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { JwtService } from "../auth/jwt.service";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LiveStreamWsService extends Socket {

  constructor(
    private jwtService: JwtService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    const isBrowser = isPlatformBrowser(platformId);
    const token = jwtService.getToken();
    super({
      url: environment.wsUrl,
      options: {
        autoConnect: false,
        reconnection: false,
        path: '/live-stream/socket.io',
        extraHeaders: {
          'authorization': token,
        },
      }
    });
  }

  connectTo() {
    this.connect();
  }

  joinToStream(liveStreamId: number) {
    return this?.emit(`join-stream`, {
      liveStreamId
    }, (r: any) => {
    });
  }

  listenStreamEvents(): undefined | Observable<any> {
    return this?.fromEvent('stream');
  }

  listenChatEvents(): undefined | Observable<any> {
    return this.fromEvent('chat');
  }

  sendChatMessage(data: { liveStreamId: number, message: string }, callback: any) {
    this.emit('chat-message', data, callback);
  }

}
